<template>
  <v-container fluid>
    <v-row class="align-center">
      <v-col
        cols
        sm="12"
        md="6"
        lg="6"
      >
        <div>
          <v-img
            :src="logo"
            aspect-ratio="2"
            contain
          />
          <p class="mb-0 d-block text-center orange--text">
            Just One Tap <br> Your All Health App
          </p>
        </div>
      </v-col>
      <v-col
        cols
        sm="12"
        md="6"
        lg="6"
      >
        <div class="d-flex justify-center">
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(login)">
              <v-container
                fluid
                class="ma-0 pa-0"
              >
                <v-row>
                  <div class="login-wrapper">
                    <p class="d-block text-center orange--text">
                      Doctor Login
                    </p>
                    <v-col
                      cols
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        vid="phone_number"
                        name="Phone No"
                        rules="required"
                      >
                        <v-text-field
                          v-model="formData.phoneNo"
                          name="phone_number"
                          outlined
                          color="orange"
                          label="Phone No"
                          placeholder="Phone Number"
                          class="search-phone"
                          type="text"
                          hide-details="auto"
                          dense
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        vid="password"
                        name="Password"
                        rules="required"
                      >
                        <v-text-field
                          v-model="formData.password"
                          name="password"
                          outlined
                          color="orange"
                          label="Password"
                          placeholder="Password"
                          :type="showPassword ? 'text' : 'password'"
                          hide-details="auto"
                          dense
                          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                          :error-messages="errors"
                          @click:append="showPassword = !showPassword"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-btn
                        block
                        color="orange"
                        elevation="2"
                        rounded
                        dark
                        type="submit"
                      >
                        Login
                      </v-btn>
                    </v-col>
                  </div>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import logo from '@/assets/images/logo.png';

extend('required', {
    ...required,
    message: "The {_field_} field is required."
});
import {fireNotification} from "@/helpers/utility";

export default {
    name: "Login",
    components: {ValidationObserver, ValidationProvider},
    data: () => ({
        logo: logo,
        formData: {
            phoneNo: '',
            password: ''
        },
        showPassword: false,
    }),

    methods: {
        async login() {
            try {
                let response = await this.$store.dispatch('authStore/login', this.formData);
                console.log(response)
                if (response?.data) {
                    fireNotification({
                        text: 'Login successful',
                        color: 'orange',
                        action: true
                    });

                    this.$router.push({name: 'ConfirmAppointments'});
                } else {
                  fireNotification({
                        text: 'Somethig went wrong!',
                        color: 'error',
                        action: true
                    });
                }
            } catch (e) {
              fireNotification({
                        text: 'Login failed',
                        color: 'error',
                        action: true
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.login-wrapper {
    padding: 15px;
    background-color: rgba(255,255,255,0.5);
}
</style>